.main-show-item-img img {
    width: 90%;
}



.img-container {
    display: flex;
    align-items: center;
    width: 50px;
    height: 50px;
    border: solid 2px rgb(236, 230, 230);
    border-radius: 10px;
    margin: 15px;
}

.img-container:hover {
    border: solid 2px #8555BF;
}