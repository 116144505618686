/* .container-cardpayments{
 display: flex;
 width:100%;
}

.items-cards{
    display: flex;
    background-color:rgb(255, 255, 255);
    flex-direction: column;
    align-items: center;
    width:90%;
    height:250px;
    margin: 20px ;
    border-radius: 5px;
    -webkit-box-shadow: 5px 4px 10px 5px #949494; 
box-shadow: 5px 4px 10px 5px #949494;
}

.items-cards h4 {
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.items-cards span{
   font-size: 15px;
   font-weight: bold;
}


.items-cards input{
  width:40px;
  height:30px;
  text-align: center;
  
}

.items-cards button{
  border:none;
  margin-bottom: 20px;
  padding:10px 30px;
  color:#fff;
  border-radius: 5px;
  background-color: #8555BF;
}

.main-container-items{
    width:60%;
}


.container-items-img{
    display: grid;
    justify-content:center;
    align-items: center;
    width:25%;
   height:100px;
   margin:auto;

}

.container-items-img img {
    height:90px;
    width:100%;
  
}




.main-completar-payment{
    display:flex;
    flex-direction: column;
    width:30%;
    height: 200px;
    position:fixed;
    right:30px;
    background-color:rgb(255, 255, 255);
    -webkit-box-shadow: 5px 4px 10px 5px #949494; 
box-shadow: 5px 4px 10px 5px #949494;
border-radius: 5px;;
}

.main-completar-payment button{
    border:none;
    border-radius: 5px;
    margin:auto;
    padding:10px;
    color:#fff;
    background-color:#8555BF ;

}
hr{
    width:80%;
    margin:auto;
}

.main-completar-payment p {
    font-size: 1.2rem;
    font-weight: bold;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    margin: 10px;
} */


.items-cards{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-left: 50px;
    width: 60%;
    height: 200px;
    margin-top: 30px;
    background-color: #fff;
    box-sizing: content-box;
    
}


.items-cards img{
    width: 7%;
margin-left: 40px;
}

.items-cards h4{
    display: flex;
    flex-direction: column;
 font-size: 1rem;
 text-align: center;
 width: 55%;
}
.items-cards span{
    margin-right: 30px;
}

.main-cantidad{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 40%;
  
}

.controlCantidad span{
    margin: 0;
}
.main-cantidad button{
    width: 30px;
    height: 30px;
    border-radius: 30px;
    border: none;
    margin: 0 15px;
    background-color: #8555BF;
    color: #fff;

}

.main-total{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main-total button{
    width: 80%;
    border: none;
    background-color: #8555BF;
    padding: 5px 0;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
    margin: 10px;
}


.main-completar-payment{
    margin-top: 30px;
    width: 30%;
    display: flex;
    flex-direction: column;
    background-color: #fff ;

}

.main-completar-payment button{
    margin: 5px 0;
    width: 70%;
    border: none;
    background-color: #8555BF;
    padding: 10px 0;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
    margin: 20px auto;

}

.main-completar-payment p{
    margin:15px 0;
    margin-left: 10px;
}

hr{
    width: 70%;
    margin:auto
}