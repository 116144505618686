.show-main {
    display: flex;
    width: 90%;
    flex-wrap: wrap;
    margin: 40px auto;
    background-color: #fff
}

.show-main-img {
    display: grid;
    justify-content: center;
    justify-items: center;
    align-items: center;
    width: 40%;
    margin: auto;
}

.show-main-img img {
    width: 70%;
}

.main-content-show {
    margin: 20px;
    width: 40%;
    border: solid 1px rgb(204, 197, 197);
    border-radius: 10px;
}

.main-content-show h2 {
    margin-top: 30px;
    font-weight: bold;
    text-align: center;
    font-size: 1.2rem;
}

.main-content-show h4 {
    font-weight: bold;
}

.main-content-show-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
}

.main-content-show-button button:nth-child(1) {
    margin: 5px 0;
    width: 70%;
    border: none;
    background-color: #8555BF;
    padding: 10px 0;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
}

.main-content-show-button button:nth-child(2) {
    margin: 5px 0;
    width: 70%;
    border: none;
    background-color: rgb(235, 231, 231);
    padding: 10px 0;
    border-radius: 5px;
    color: #8555BF;
    font-weight: bold;
}

.main-price-img {
    margin-top: 30px;
    text-align: center;
}

.main-price-img p {
    font-size: 1rem;
    font-weight: 500;
}

.main-price-img hr {
    width: 50%;
    margin: auto;
    margin-bottom: 20px;
}

.main-price-img img {
    width: 33%;
    margin: 0 15px;
}