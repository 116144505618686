.main-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: solid 1px rgb(230, 223, 223);
    background-color: #fff;
    width: 33%;
    height: 490px;
    margin: 15px auto;
    border-radius: 5px;
    box-shadow: -15px -6px 26px -2px rgba(174, 157, 157, 0.49);
    -webkit-box-shadow: -15px -6px 26px -2px rgba(174, 157, 157, 0.49);
    -moz-box-shadow: -15px -6px 26px -2px rgba(174, 157, 157, 0.49);
}

.login-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px 0;
}

.login-container h1 {
    width: 70%;
    font-size: 1.6rem;
    text-align: center;
}

.form-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    margin-top: 25px;
}

.form-login label {
    width: 100%;
    text-align: justify;
}

.form-login input {
    padding: 10px;
    border-radius: 7px;
    width: 100%;
    border: solid 1px rgba(199, 207, 185, 0.945);
    font-size: 1rem;
    margin: 5px 0;
    margin-bottom: 30px;
}

.login-container input:focus {
    outline: 2px solid rgba(51, 80, 243, 0.829);
}

.login-buttons {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin: 20px 0;
}

.login-buttons a {
    text-decoration: none;
}

.login-buttons button {
    border-radius: 5px;
    padding: 10px;
    margin: 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-buttons img {
    width: 20px;
    margin-left: 10px;
}

.login-buttons button:nth-child(1) {
    background-color: rgb(87, 111, 243);
    color: #fff;
    border: none;
    transition: all 0.2s ease-out;
    font-weight: 700;
}

.login-buttons button:nth-child(2) {
    color: rgba(22, 56, 253, 0.829);
    background-color: #fff;
    border: none;
    transition: all 0.2s ease-out;
    font-weight: 700;
}

.login-buttons button:nth-child(3) {
    color: rgba(22, 56, 253, 0.829);
    background-color: #fff;
    border: none;
    transition: all 0.2s ease-out;
    font-weight: 700;
}

.login-buttons button:hover:nth-child(1) {
    background-color: rgba(34, 89, 241, 0.993);
}

.login-buttons button:hover:nth-child(2) {
    background-color: rgba(66, 116, 255, 0.096);
}

.login-buttons button:hover:nth-child(3) {
    background-color: rgba(66, 116, 255, 0.096);
}