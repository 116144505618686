.main-Container-Empty{
   margin-top: 70px;
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}

.main-Container-Empty img{
    width: 10%;
    margin-bottom: 30px;
 }

.main-Container-Empty h2{
 color: #8555BF;
 margin-bottom: 30px;

}

.main-Container-Empty h3{
    color: rgb(8, 8, 8);
    margin-bottom: 60px;

   }

.main-Container-Empty button{
    margin: 5px 0;
    width: 20%;
    border: none;
    background-color: #8555BF;
    padding: 10px 0;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
}